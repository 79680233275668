import {
  Input as MantineInput,
  InputDescriptionProps as MantineInputDescriptionProps
} from '@mantine/core';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { Group, GroupProps } from '../../Group';
import { InfoDecorator } from '../../InfoDecorator';
import { OptionalSkeleton } from '../../Skeleton';
import { TooltipProps } from '../../Tooltip';

export interface FormInputDescriptionProps
  extends MantineInputDescriptionProps {
  tooltip?: Omit<TooltipProps, 'children'>;
  showSkeleton?: boolean;
  rightSection?: ReactNode;
  containerAlignment?: GroupProps['align'];
}

export const FormInputDescription = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FormInputDescriptionProps>
>(
  (
    { rightSection, containerAlignment = 'baseline', mb = 5, mt, ...rest },
    ref
  ) => {
    return !rightSection ? (
      <_Description ref={ref} mb={mb} mt={mt} {...rest} />
    ) : (
      <Group
        justify="space-between"
        mb={mb}
        mt={mt}
        align={containerAlignment}
        gap="0.15rem"
      >
        <_Description {...rest} ref={ref} />
        {rightSection}
      </Group>
    );
  }
);

const _Description = forwardRef<
  HTMLDivElement,
  Omit<FormInputDescriptionProps, 'rightSection'>
>(({ tooltip, children, showSkeleton, ...rest }, ref) => {
  return (
    <InfoDecorator {...tooltip}>
      <MantineInput.Description ref={ref} w="fit-content" {...rest}>
        <OptionalSkeleton visible={showSkeleton}>{children}</OptionalSkeleton>
      </MantineInput.Description>
    </InfoDecorator>
  );
});
