'use client';
import {
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  ButtonGroup as MantineButtonGroup,
  createVarsResolver as createMantineVarsResolver,
  ElementProps as MantineElementProps,
  getFontSize as getMantineFontSize,
  getRadius as getMantineRadius,
  getSize as getMantineSize,
  MantineColor,
  MantineGradient,
  MantineRadius,
  MantineSize,
  PolymorphicFactory as MantinePolymorphicFactory,
  polymorphicFactory as mantinePolymorphicFactory,
  StylesApiProps as MantineStylesApiProps,
  UnstyledButton as MantineUnstyledButton,
  useProps as useMantineProps,
  useStyles as useMantineStyles
} from '@mantine/core';
import React, { CSSProperties } from 'react';

import { OptionalSkeleton } from '../Skeleton';
import { Spinner, SpinnerProps } from '../Spinner';
import { OptionalTooltip, TooltipProps } from '../Tooltip';
import classes from './Button.module.css';
import {
  ButtonVariants,
  IMayHaveStyledButtonVariantProps,
  resolveButtonStyledVariantColors
} from './Button.styles';

export type ButtonStylesNames =
  | 'root'
  | 'inner'
  | 'loader'
  | 'section'
  | 'label';

export type ButtonCssVariables = {
  root:
    | '--button-justify'
    | '--button-height'
    | '--button-padding-x'
    | '--button-fz'
    | '--button-radius'
    | '--button-bg'
    | '--button-hover'
    | '--button-hover-color'
    | '--button-color'
    | '--button-bd';
};

export interface ButtonTooltipProps extends Omit<TooltipProps, 'children'> {
  showWhenDisabled?: boolean;
}

interface Empty {}

export interface ButtonProps
  extends IMayHaveStyledButtonVariantProps,
    MantineBoxProps,
    MantineStylesApiProps<ButtonFactory> {
  /** Controls button `height`, `font-size` and horizontal `padding`, `'sm'` by default */
  size?: MantineSize | `compact-${MantineSize}` | (string & Empty);

  id?: string;

  /** Key of `theme.colors` or any valid CSS color, `theme.primaryColor` by default */
  color?: MantineColor;

  /** Sets `justify-content` of `inner` element, can be used to change distribution of sections and label, `'center'` by default */
  justify?: React.CSSProperties['justifyContent'];

  /**
   * @deprecated use leftSection instead
   */
  leftIcon?: React.ReactNode;

  /** Content displayed on the left side of the button label */
  leftSection?: React.ReactNode;

  /**
   * @deprecated use rightSection instead
   */
  rightIcon?: React.ReactNode;

  /** Content displayed on the right side of the button label */
  rightSection?: React.ReactNode;

  /** Determines whether button should take 100% width of its parent container, `false` by default */
  fullWidth?: boolean;

  /** Key of `theme.radius` or any valid CSS value to set `border-radius`, `theme.defaultRadius` by default */
  radius?: MantineRadius;

  /** Gradient configuration used when `variant="gradient"`, default value is `theme.defaultGradient` */
  gradient?: MantineGradient;

  /** Indicates disabled state */
  disabled?: boolean;

  //Wraps button in loading skeleton
  showSkeleton?: boolean;

  /** Determines whether the `Loader` component should be displayed over the button */
  busy?: boolean;

  /** Props added to the `Loader` component (only visible when `loading` prop is set) */
  busyProps?: SpinnerProps;

  /** Determines whether button text color with filled variant should depend on `background-color`. If luminosity of the `color` prop is less than `theme.luminosityThreshold`, then `theme.white` will be used for text color, otherwise `theme.black`. Overrides `theme.autoContrast`. */
  autoContrast?: boolean;

  /** Button content */
  children?: React.ReactNode;

  tooltip?: ButtonTooltipProps;

  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  href?: string;

  download?: string;

  target?: string;

  tabIndex?: number;

  type?: MantineElementProps<'button'>['type'];

  //By default our buttons do not have overflow hidden
  //This is because we have a lot of buttons that have display flex parents and the overflow breaks the layout in some places.
  //We should fix this but in the meantime overflowHidden is opt in.
  //Having no overflow hidden also causes a weird look for our loading indicators where they take up space on isBusy and the
  //animation looks jank because it enters from outside the button. :(
  overflowHidden?: boolean;
}

export type ButtonFactory = MantinePolymorphicFactory<{
  props: ButtonProps;
  defaultRef: HTMLButtonElement;
  defaultComponent: 'button';
  stylesNames: ButtonStylesNames;
  vars: ButtonCssVariables;
  variant: ButtonVariants;
  staticComponents: {
    Group: typeof MantineButtonGroup;
  };
}>;

const defaultProps: Partial<ButtonProps> = {};

const varsResolver = createMantineVarsResolver<ButtonFactory>((theme, p) => {
  const {
    radius,
    gradient,
    styledVariantProps: styledProps,
    size,
    autoContrast,
    justify
  } = p;
  let color = p.color;
  let variant = !!styledProps ? 'styled' : p.variant;

  if (!color && (variant === 'subtle' || variant === 'transparent')) {
    color = 'gray';
  }

  if ((variant || '').endsWith('-danger')) {
    color = 'red.7';
  } else if ((variant || '').endsWith('-success')) {
    color = 'green';
  }

  if ((variant || '').startsWith('filled-')) {
    variant = 'filled';
  } else if ((variant || '').startsWith('light-')) {
    variant = 'light';
  }

  const colors =
    variant === 'styled'
      ? resolveButtonStyledVariantColors({
          theme,
          styledVariantProps: styledProps
        })
      : theme.variantColorResolver({
          color: color || theme.primaryColor,
          theme,
          gradient,
          variant: variant || 'filled',
          autoContrast
        });

  return {
    root: {
      '--button-justify': justify,
      '--button-height': getMantineSize(size, 'button-height'),
      '--button-padding-x': getMantineSize(size, 'button-padding-x'),
      '--button-fz': size?.includes('compact')
        ? getMantineFontSize(size.replace('compact-', ''))
        : getMantineFontSize(size),
      '--button-radius':
        radius === undefined ? undefined : getMantineRadius(radius),
      '--button-bg': color || variant ? colors.background : undefined,
      '--button-hover': color || variant ? colors.hover : undefined,
      '--button-color': color || variant ? colors.color : undefined,
      '--button-bd': color || variant ? colors.border : undefined,
      '--button-hover-color': color || variant ? colors.hoverColor : undefined
    }
  };
});

type ButtonReturnType = ReturnType<
  typeof mantinePolymorphicFactory<ButtonFactory>
>;

export const Button = mantinePolymorphicFactory<ButtonFactory>(
  (_props, ref) => {
    const props = useMantineProps('Button', defaultProps, _props);
    const {
      style,
      vars,
      className,
      disabled: disabledProp,
      children,
      leftIcon,
      leftSection: leftSectionProp,
      rightIcon,
      rightSection: rightSectionProp,
      fullWidth,
      busy,
      busyProps,
      classNames,
      styles,
      unstyled,
      onClick,
      showSkeleton,
      tooltip,
      tabIndex,
      overflowHidden,

      //These are used in the variant resolver
      justify,
      styledVariantProps,
      variant,
      radius,
      gradient,
      color,
      autoContrast,

      ...others
    } = props;

    // The default color from 'useComponentDefaultProps' will default to themes primary color
    // However for variants subtle and transparent we want the default to be gray
    if (variant === 'subtle' || variant === 'transparent') {
      props.color = _props.color || 'gray';
    }

    if (color === 'var(--color-komo-yellow)') {
      props.color = '#f1e52c';
    }

    const getStyles = useMantineStyles<ButtonFactory>({
      name: 'Button',
      props,
      classes,
      className,
      style,
      classNames,
      styles,
      unstyled,
      vars,
      varsResolver
    });

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      onClick?.(e as any);
    };

    if (others.href && !(others as any).component) {
      (others as any).component = 'a';
    }

    const disabled = disabledProp || busy || showSkeleton || false;

    const { showWhenDisabled, ...tooltipProps } = tooltip || {};

    const tooltipHidden = disabled && !showWhenDisabled;

    const disabledDataProps: CSSProperties = {};
    if (disabled) {
      disabledDataProps['aria-disabled'] = '';
      disabledDataProps['data-disabled'] = '';
    }

    const leftSection = leftSectionProp ?? leftIcon;
    const rightSection = rightSectionProp ?? rightIcon;
    const hasLeftSection = !!leftSection;
    const hasRightSection = !!rightSection;

    return (
      <OptionalSkeleton visible={showSkeleton}>
        <OptionalTooltip disabled={tooltipHidden} {...(tooltipProps || {})}>
          <MantineUnstyledButton
            ref={ref}
            {...getStyles('root', { active: !disabled })}
            unstyled={unstyled}
            variant={variant}
            disabled={disabled || busy}
            mod={{
              disabled: disabled,
              loading: busy,
              block: fullWidth,
              'overflow-hide': overflowHidden,
              'with-left-section': hasLeftSection,
              'with-right-section': hasRightSection
            }}
            {...(others as any)}
            onClick={handleClick}
            tabIndex={disabled ? -1 : tabIndex}
            {...disabledDataProps}
          >
            <MantineBox component="span" {...getStyles('loader')} aria-hidden>
              <Spinner
                color="var(--button-color)"
                size="calc(var(--button-height) / 1.8)"
                {...busyProps}
              />
            </MantineBox>

            <span {...getStyles('inner')}>
              {leftSection && (
                <MantineBox
                  component="span"
                  {...getStyles('section')}
                  mod={{ position: 'left' }}
                >
                  {leftSection}
                </MantineBox>
              )}

              <MantineBox
                component="span"
                mod={{ loading: busy }}
                {...getStyles('label')}
              >
                {children}
              </MantineBox>

              {rightSection && (
                <MantineBox
                  component="span"
                  {...getStyles('section')}
                  mod={{ position: 'right' }}
                >
                  {rightSection}
                </MantineBox>
              )}
            </span>
          </MantineUnstyledButton>
        </OptionalTooltip>
      </OptionalSkeleton>
    );
  }
) as ButtonReturnType;

Button.classes = classes;
Button.displayName = 'Button';
Button.Group = MantineButtonGroup;
